import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

const Image = ({ data }) => {
  return (
    <S.ImageContainer>
      <S.Image>
        <GatsbyImage
          image={data.image.childImageSharp.gatsbyImageData}
          placeholder="blurred"
        />
      </S.Image>
    </S.ImageContainer>
  )
}

export default Image

const S = {}

S.Image = styled.box`
  height: 100%;
  width: 100%;

  ${breakpoints({
    xs: css`
      max-height: 185;
      max-width: 151;
      min-width: 151;
      position: absolute;
      right: -23;
    `,
    md: css`
      max-height: unset;
      max-width: unset;
      min-width: unset;
      position: relative;
      right: 0;
    `,
  })}
`

S.ImageContainer = styled.div`
  ${breakpoints({
    xs: css`
      position: relative;
      overflow: hidden;

      width: 100%;
      max-height: 185;
      min-height: 185;
    `,
    md: css`
      max-width: 194;
      min-width: 194;

      max-height: 238;
      min-height: 238;
    `,
  })}
`
