import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { H1 } from '@atoms/Typography'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'

const Content = ({ data }) => {
  const { title, paragraphs } = data
  return (
    <S.Box>
      <S.H1 as="h1">{title}</S.H1>
      <S.HTMLContent
        dangerouslySetInnerHTML={{
          __html: transformMDtoHTML(paragraphs),
        }}
      />
    </S.Box>
  )
}
export default Content

const S = {}

S.Box = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding-left: 15px;
  padding-right: 15px;
  ${breakpoints({
    xs: css`
      row-gap: 30;
      padding-left: 30;
      padding-right: 30;
    `,
    md: css`
      row-gap: 59;
      max-width: 688;
    `,
  })};
`

S.H1 = styled(H1)`
  font-weight: ${th('fontWeights.bold')};
  ${breakpoints({
    xs: css`
      line-height: 1.1;
    `,
    md: css`
      line-height: 1.2;
    `,
  })}
`

S.HTMLContent = styled(HTMLContent)`
  ${breakpoints({
    md: css`
      p {
        font-size: 24;
      }
    `,
  })}
`
