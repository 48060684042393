import React from 'react'
import Services from './Services'

const ExternalSection = ({ block, data }) => {
  const { section } = block
  const services = data.services.nodes
  return (
    <>
      {section === 'services' && (
        <Services id={section} title={block.title} services={services} />
      )}
    </>
  )
}

export default ExternalSection
