import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { TextLink, H3, H4 } from '@atoms/Typography'
import { th } from '@xstyled/system'
import { Link } from 'gatsby'

const ServiceCard = ({ data }) => {
  const { slug, title, excerpt, blocks } = data.frontmatter
  const listServices =
    blocks.find((block) => block.type === 'services')?.list || []

  return (
    <S.Wrapper>
      <S.H3>
        <Link title={title} to={`/about/${slug}/`}>
          {title}
        </Link>
      </S.H3>
      <S.H4>{excerpt}</S.H4>
      <S.TextLink internal title={title} to={`/about/${slug}/`}>
        Read more
      </S.TextLink>

      <S.List>
        {listServices.map((item) => (
          <S.Item key={item}>{item}</S.Item>
        ))}
      </S.List>
    </S.Wrapper>
  )
}

export default ServiceCard

const S = {}

S.Wrapper = styled.article`
  ${breakpoints({
    xs: css`
      padding-left: 30;
      padding-right: 30;
      margin-left: -30;
      margin-right: -30;
    `,
    md: css`
      padding: 0;
      color: ${th('colors.dark')};
      margin-left: 0;
      margin-right: 0;
    `,
  })}
`

S.H3 = styled(H3)`
  ${breakpoints({
    xs: css`
      line-height: 1.2;
      margin-bottom: 20;
    `,
    md: css`
      line-height: 1.4;
      margin-bottom: 23;
    `,
  })}
`

S.H4 = styled(H4)`
  line-height: 1.5;
  font-weight: ${th('fontWeights.normal')};
  ${breakpoints({
    xs: css`
      font-size: 18;
      margin-bottom: 10;
    `,
    md: css`
      font-size: 24;
      margin-bottom: 20;
    `,
  })}
`

S.TextLink = styled(TextLink)`
  line-height: 1.4;
  ${breakpoints({
    xs: css`
      font-size: 18;
    `,
    md: css`
      font-size: 22;
    `,
  })}
`

S.List = styled.ulBox`
  ${breakpoints({
    xs: css`
      margin-top: 50;
    `,
    md: css`
      margin-top: 56;
    `,
  })}
`

S.Item = styled.li`
  font-weight: ${th('fontWeights.normal')};
  padding-bottom: 20;
  line-height: 1.4;
  ${breakpoints({
    xs: css`
      font-size: 16;
      &:last-of-type {
        padding-bottom: 0;
      }
    `,
    md: css`
      font-size: 20;
    `,
  })}
`
