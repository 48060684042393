import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import arrowLeft from '@assets/svg/arrow-left.svg'

const BackButton = ({ to, children }) => {
  return (
    <S.Back to={to}>
      <S.BackIcon />
      {children}
    </S.Back>
  )
}

export default BackButton

const S = {}

S.Back = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: ${th('fontWeights.bold')};
  align-items: center;
  cursor: pointer;
  ${breakpoints({
    xs: css`
      margin-bottom: 40;
      width: 100%;
      font-size: 14;
    `,
    md: css`
      margin-bottom: 0;
      margin-top: 20;
      width: 200;
      font-size: 17;
    `,
  })}
`

S.BackIcon = styled(arrowLeft)`
  width: 27;
  height: 16;
  margin-right: 10;
`
