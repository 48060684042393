import React from 'react'
import { TitleSection } from '@atoms/Typography'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { Section } from '@atoms/Grid'
import idgen from '@utils/idgen'
import ImageContent from './ImageContent'

const WhatWeDoSection = ({ data }) => {
  const { sectionTitle, items } = data
  const copyImages = items.filter((item) => item.type === 'copyImage')

  return (
    <Section>
      {sectionTitle && <TitleSection>{sectionTitle}</TitleSection>}
      {copyImages.length > 0 &&
        copyImages.map((item, index) => (
          <S.ImageContent
            key={`${idgen()}-${index}`}
            inverse={item.inverse}
            data={item}
          />
        ))}
    </Section>
  )
}

export default WhatWeDoSection

const S = {}

S.ImageContent = styled(ImageContent)`
  ${breakpoints({
    xs: css`
      margin-bottom: 100.13;
      &:last-of-type {
        margin-bottom: 80.13;
      }
    `,
    md: css`
      margin-bottom: 120;
      &:last-of-type {
        margin-bottom: 164;
      }
    `,
  })}
`
