import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { TitleSection, H2, H3 } from '@atoms/Typography'
import { Section } from '@atoms/Grid'
import idgen from '@utils/idgen'
import ImageContent from './ImageContent'
import Offices from '../Offices'
import OurStoryParagraphs from './OurStoryParagraphs'

const OurStorySection = ({ data }) => {
  const { sectionTitle, items } = data
  const copyImages = items.filter((item) => item.type === 'copyImage')
  const offices = items.filter((item) => item.type === 'office')

  return (
    <Section id="story">
      <S.Writings>
        <H3>{sectionTitle}</H3>
        <div>
          {copyImages.length > 0 &&
            copyImages.map((item) => (
              <S.Content key={idgen()}>
                <H2 mb={{ xs: 30, xl: 60 }} as="h2">
                  {item.title}
                </H2>
                <OurStoryParagraphs data={item} />
              </S.Content>
            ))}
        </div>
      </S.Writings>
      {offices.length > 0 && <Offices offices={offices} />}
    </Section>
  )
}

export default OurStorySection

const S = {}

S.TitleSection = styled(TitleSection)`
  letter-spacing: -0.02em;
  margin: 0;
`

S.Writings = styled.div`
  display: grid;
  ${breakpoints({
    xs: css`
      grid-template-rows: auto auto;
      row-gap: 50;
    `,
    md: css`
      grid-template-rows: unset;
      grid-template-columns: auto auto;
      column-gap: 100;
    `,
    lg: css`
      grid-template-rows: unset;
      grid-template-columns: auto auto;
      column-gap: 199;
    `,
  })}
`

S.ImageContent = styled(ImageContent)`
  ${breakpoints({
    xs: css`
      margin-bottom: 100.13;
      &:last-of-type {
        margin-bottom: 80.13;
      }
    `,
    md: css`
      margin-bottom: 120;
      &:last-of-type {
        margin-bottom: 164;
      }
    `,
  })}
`

S.H3 = styled(H3)`
  letter-spacing: -0.02em;
  ${breakpoints({
    xs: css`
      line-height: 1.2;
    `,
    md: css`
      line-height: 1.3;
      max-width: 570;
    `,
  })}
`

S.Content = styled.divBox`
  width: 100%;
`
