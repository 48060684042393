import React from 'react'
import { graphql } from 'gatsby'
import styled from '@xstyled/styled-components'
import Layout from '@organisms/Layout'
import Hero from '@organisms/About/Hero'
import Banner from '@organisms/About/Banner'
import Section from '@organisms/About/Section'
import ExternalSection from '@organisms/About/ExternalSection'
import idgen from '@utils/idgen'
import useBreakpoint from '@hooks/useBreakpoint'

const About = ({ data }) => {
  const { blocks, meta } = data.about.nodes[0].frontmatter
  const { upTo } = useBreakpoint()

  return (
    <Layout
      hamburgerMenu
      title={meta.title}
      description={meta.description}
      buttonColor={upTo('md') ? 'yellow' : 'dark'}
    >
      <S.Main>
        {blocks.map((item) => {
          switch (item.type) {
            case 'hero':
              return <Hero data={item} key={idgen()} />
            case 'banner':
              return <Banner data={item} key={idgen()} />
            case 'section':
              return <Section data={item} key={idgen()} />
            case 'externalSection':
              return <ExternalSection block={item} data={data} key={idgen()} />
            default:
              return null;
          }
        })}
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox``

export default About

// Set here the ID of the home page.
export const pageQuery = graphql`
query AboutPage {
  about: allMarkdownRemark(
    filter: { frontmatter: { slug: { eq: "about" } } }
  ) {
    nodes {
      frontmatter {
        ...RepeaterFragment
      }
    }
  }
  services: allMarkdownRemark(
    filter: { frontmatter: {collection: {eq: "service"}} }, 
    sort: { order: ASC, fields: [frontmatter___order] }
  ) {
    nodes {
      frontmatter {
        slug
        order
        ...RepeaterFragment
      }
    }
  }
}
`
