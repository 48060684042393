import React from 'react'
import styled, { breakpoints, css, th } from '@xstyled/styled-components'
import { GatsbyImage } from "gatsby-plugin-image";

import { Text } from '@atoms/Typography'

const Offices = ({ offices }) => {
  return (
    <S.Wrapper>
      {offices.map((office, index) => {
        const isOdd = index % 2 !== 0
        return (
          <S.Office
            key={office.title}
            mt={{ md: isOdd ? 110 : 0 }}
            mb={{
              xs: index === 0 ? 70 : 0,
              md: 0,
            }}
          >
            <S.Text as="h3" mb={0}>
              {office.title}
            </S.Text>
            <GatsbyImage image={office.image.childImageSharp.gatsbyImageData} alt={office.title} />
          </S.Office>
        );
      })}
    </S.Wrapper>
  );
}

export default Offices

const S = {}

S.Wrapper = styled.divBox`
  display: flex;
  justify-content: space-between;
  ${breakpoints({
    xs: css`
      flex-direction: column;
      margin-top: 80;
      margin-bottom: 80;
    `,
    md: css`
      flex-direction: row;
      margin-top: 130;
      margin-bottom: 90;
    `,
  })}
`

S.Office = styled.divBox`
  ${breakpoints({
    xs: css`
      width: 100%;
    `,
    md: css`
      max-width: 45%;
    `,
    xl: css`
      max-width: 486;
    `,
  })}
`

S.Text = styled(Text)`
  line-height: 1.4;
  letter-spacing: -0.02em;
  text-align: center;
  font-weight: ${th('fontWeights.bold')};

  ${breakpoints({
    xs: css`
      font-size: 28;
      padding-bottom: 30;
    `,
    md: css`
      font-size: 36;
      padding-bottom: 48;
    `,
  })}
`
