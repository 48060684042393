import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Text, H4 } from '@atoms/Typography'

const Item = ({ data }) => {
  const { image, title, paragraphs } = data
  return (
    <S.Wrapper>
      <S.Image image={image.childImageSharp.gatsbyImageData} alt={title} />
      <S.Writings>
        <S.H4 as="h3">{title}</S.H4>
        <S.Paragraphs>{paragraphs}</S.Paragraphs>
      </S.Writings>
    </S.Wrapper>
  )
}

export default Item

const S = {}

S.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoints({
    xs: css`
      margin-bottom: 70;

      &:last-of-type {
        margin-bottom: 0;
      }
    `,
    md: css`
      margin-bottom: 0;
    `,
  })}
`

S.Image = styled(GatsbyImage)`
  object-fit: cover;
  ${breakpoints({
    xs: css`
      height: 240;
      width: 240;
    `,
    md: css`
      max-height: 240;
      max-width: 240;
      height: 100%;
      width: 240;
    `,
    lg: css`
      max-height: 299;
      max-width: 300;
      height: 100%;
      width: 300;
    `,
  })}
`

S.Writings = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  ${breakpoints({
    xs: css`
      row-gap: 15;
    `,
    md: css`
      row-gap: 14;
    `,
  })}
`

S.H4 = styled(H4)`
  line-height: 1.2;

  ${breakpoints({
    lg: css`
      font-size: 36;
    `,
  })}
`

S.Paragraphs = styled(Text)`
  line-height: 1.4;
  font-size: 18;
  ${breakpoints({
    lg: css`
      max-width: 383.47;
      font-size: 20;
    `,
  })}
`
