import React from 'react'
import { lighten } from 'polished'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { Container } from '@atoms/Grid'
import { TitleSection } from '@atoms/Typography'
import ServiceCard from '@molecules/ServiceCard'

const Services = ({ title, services, id }) => {
  return (
    <S.Section id={id}>
      <Container>
        <TitleSection>{title}</TitleSection>
        <S.List>
          {services.map((service) => (
            <S.Item key={service.frontmatter.title}>
              <ServiceCard data={service} />
            </S.Item>
          ))}
        </S.List>
      </Container>
    </S.Section>
  )
}

export default Services

const S = {}

S.Item = styled.li`
  ${breakpoints({
    xs: css`
      padding-bottom: 70;
      &:last-of-type {
        padding-bottom: 0;
      }
    `,
    md: css`
      padding-bottom: 0;
    `,
  })}
`

S.List = styled.ul`
  list-style: none;
  list-style-type: none;
  ${breakpoints({
    xs: css``,
    md: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 135;
      column-gap: 126;
    `,
  })}
`

S.Section = styled.section`
  background: ${({ theme }) => lighten(0.5, theme.colors.grey)};
  ${breakpoints({
    xs: css`
      padding-top: 80;
      padding-bottom: 80;
      margin-bottom: 100;
    `,
    md: css`
      padding-top: 130;
      padding-bottom: 130;
      margin-bottom: 180;
    `,
  })}
`
