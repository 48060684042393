import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { Text } from '@atoms/Typography'
import idgen from '@utils/idgen'
import useBreakpoint from '@hooks/useBreakpoint'

const OurStoryParagraphs = ({ data }) => {
  const { paragraphs } = data
  return (
    <>
      {paragraphs.split('\n').map((paragraph) => (
        <S.Paragraph key={idgen()}>{paragraph}</S.Paragraph>
      ))}
    </>
  )
}

export default OurStoryParagraphs

const S = {}

S.Paragraph = styled(Text)`
  margin-bottom: 30;
  line-height: 150%;
  ${breakpoints({
    md: css`
      font-size: 24;
    `,
  })}
`
