import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { Container } from '@atoms/Grid'
import BackButton from './hero/BackButton'
import Image from './hero/Image'
import Content from './hero/Content'

const Hero = ({ back, invertColor, data, background, ...props }) => {
  return (
    <S.Wrapper invertColor={invertColor} background={background} {...props}>
      <S.Container>
        {back && <BackButton to="/about/">Back</BackButton>}

        <S.ContentContainer>
          <Image data={data} />
          <Content data={data} />
        </S.ContentContainer>
      </S.Container>
    </S.Wrapper>
  )
}

export default Hero

const S = {}

S.ContentContainer = styled.divBox`
  width: 100%;
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
    `,
    md: css`
      display: grid;
      grid-template-columns: 194px auto;
    `,
    lg: css`
      column-gap: 107;
    `,
    xl: css`
      column-gap: 214;
    `,
  })}
`

S.Wrapper = styled.section`
  ${({ invertColor }) =>
    invertColor &&
    css`
      color: ${th('colors.light')};
    `}
  ${({ background }) =>
    background &&
    css`
      background-color: ${th(`colors.${background}`)};
    `}
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      padding-top: 130;
      padding-bottom: 80;
      width: 100%;
    `,
    md: css`
      flex-direction: row;
      align-items: flex-start;
      padding-top: 202;
      padding-bottom: 180;
    `,
  })}
`

S.Container = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  display: flex;

  ${breakpoints({
    xs: css`
      flex-direction: column;
    `,
    md: css`
      flex-direction: row;
      align-items: flex-start;
    `,
  })}
`
