import React from 'react'
import WhatWeDoSection from './section/WhatWeDoSection'
import OurStorySection from './section/OurStorySection'

const Section = ({ data }) => {
  const { sectionTitle } = data

  return (
    <>
      {sectionTitle === 'What we do' && <WhatWeDoSection data={data} /> }
      {sectionTitle === 'Our story' && <OurStorySection data={data} /> }
    </>
  )
}

export default Section