import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { H3 } from '@atoms/Typography'
import { Container } from '@atoms/Grid'
import idgen from '@utils/idgen'
import ArrowForward from '@assets/svg/arrow_forward.svg'
import Item from './banner/Item'

import { Row, Col } from '@atoms/Grid'

const Banner = ({ data }) => {
  const { sectionTitle, items } = data
  return (
    <S.Section>
      <Container>
        <S.H3 as="h2">{sectionTitle}</S.H3>

        <Row>
          {items.map((item, index) => (
            <Col col={{ xs: 1, md: 1 / 3 }} position="relative" key={idgen()}>
              <Item data={item} />
              {index < items.length - 1 ? <S.ArrowForward /> : null}
            </Col>
          ))}
        </Row>
      </Container>
    </S.Section>
  )
}

export default Banner

const S = {}

S.Section = styled.section`
  background-color: ${th('colors.dark')};
  color: ${th('colors.light')};
  text-align: center;

  ${breakpoints({
    xs: css`
      padding-top: 80;
      padding-bottom: 80;
      margin-bottom: 80;
    `,
    md: css`
      padding-top: 120;
      padding-bottom: 120;
      margin-bottom: 180;
    `,
  })}
`

S.H3 = styled(H3)`
  line-height: 1.2;
  padding-left: 11;
  padding-right: 11;
  margin-bottom: 30;
`

S.ItemWrapper = styled.div`
  ${breakpoints({
    md: css`
      position: relative;
    `,
  })}
`

S.ArrowForward = styled(ArrowForward)`
  ${breakpoints({
    xs: css`
      display: none;
    `,
    md: css`
      position: absolute;
      display: block;
      right: -7.5px;
      top: 0;

      height: 100%;
      max-height: 32;
      width: 100%;
      max-width: 35.96;
      margin-top: 134;
    `,
  })}
`
