import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'
import { H3 } from '@atoms/Typography'
import { Row, Col } from '@atoms/Grid'
import FadeInView from '@atoms/FadeInView'
import { ifProp } from 'styled-tools'

const ImageContent = ({ inverse, noImage, data, ...props }) => {
  const { title, paragraphs, image } = data
  return (
    <S.Wrapper inverse={inverse} {...props}>
      {image && (
        <Col col={{ xs: 1, md: 5 / 12 }}>
          <FadeInView>
            <S.Image
              image={image.childImageSharp.gatsbyImageData}
              alt={title}
              display={noImage ? 'none' : 'block'}
            />
          </FadeInView>
        </Col>
      )}

      <Col col={{ xs: 1, md: 6 / 12 }} ml={1 / 12}>
        <S.Content>
          <FadeInView>
            <S.Title mb="22px">{title}</S.Title>
            <HTMLContent
              dangerouslySetInnerHTML={{
                __html: transformMDtoHTML(paragraphs),
              }}
            />
          </FadeInView>
        </S.Content>
      </Col>
    </S.Wrapper>
  )
}

export default ImageContent

const S = {}

S.Wrapper = styled(Row)`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
    `,
    md: css`
      justify-content: space-between;
      align-items: center;
      flex-direction: ${ifProp('inverse', 'row-reverse', 'row')};
    `,
  })}
`

S.Content = styled.divBox`
  width: 100%;
  /* display: grid;
  grid-template-rows: auto;

  ${breakpoints({
    xs: css`
      row-gap: 20;
    `,
    md: css`
      max-width: 286;
      row-gap: 30;
      margin-top: 110;
    `,
    lg: css`
      max-width: 486;
    `,
  })} */
`

S.Image = styled(GatsbyImage)`
  object-fit: cover;
  ${breakpoints({
    xs: css`
      width: 100%;
      height: 377.87;
      margin-bottom: 50;
    `,
    md: css`
      height: 100%;
      max-width: 386;
      margin-bottom: 0;
    `,
    xl: css`
      height: 583;
      max-width: 486;
    `,
  })}
`
S.Title = styled(H3)`
  line-height: 1.2;
  letter-spacing: -0.02em;
`
